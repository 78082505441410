.button {
    padding: 12px 15px;
    color: white;
    border: 1px solid #6142FF;
    
    cursor: pointer;
    background: transparent;
    
    font-family: var(--ppsupplymono-regular);
    

    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    transition: all 0.4s;
  }
  .button:hover{
    background-color: aliceblue;
    color: black;
  }


  .solid{
    background-color: #6142FF;
  }

  
@media (max-width: 768px) {
    .button {
      padding: 8px;
      font-size: 10px;
    }
}