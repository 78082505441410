@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "PPSupplyMono-Regular";
  /*Can be any text*/
  src: local("PPSupplyMono-Regular"),
    url("./fonts/PPSupplyMono-Regular.ttf") format("truetype");
}

/* @font-face {
  font-family: "PPNeueBit-Regular";
  
  src: local("PPNeueBit-Regular"),
    url("./fonts/PPNeueBit-Regular.ttf") format("truetype");
} */

body{
  background-image: url("./assets/images/moonbase-earth.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  min-height: 100vh;
  width: 100%;
}

:root {
  /* Fonts */
  --ppsupplymono-regular: PPSupplyMono-Regular, sans-serif;
  /* --ppneuebit-regular: PPNeueBit-Regular, sans-serif; */
  --inter-font: "Inter", sans-serif;

  --white: #fff;
  /* --cyan: #22ddd2; */
  /* --body-light: #515151;
  --dark-gray: #2c323d;
  --black: #000000; */
  --bg-black: #0b0b0b;
  /* --light-theme-blue: #2e73ea;
  --purple: #ad00ff; */
  /* --gradient-purple: linear-gradient(
    88deg,
    var(--purple) 3.38%,
    var(--light-theme-blue) 90.79%
  );
  --gradient-blue-cyan: linear-gradient(
    88deg,
    var(--light-theme-blue) 3.38%,
    var(--cyan) 90.79%
  ); */
  /* --nav-bg: #000000; */
  --nav-bg: rgba(217, 217, 217, 0.1);
}
html.dark {
  --nav-bg: rgba(217, 217, 217, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
span {
  font-family: var(--ppsupplymono-regular);
}
p,
div {
  font-family: var(--inter-font);
}
/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-lato);
} */

.text-cyan {
  color: var(--cyan);
}
.text-blue {
  color: var(--light-theme-blue);
}
.text-purple {
  color: var(--purple);
}
.text-gradient-purple {
  background: var(--gradient-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.text-gradient-blue-cyan {
  background: var(--gradient-blue-cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.border-cyan {
  border-color: var(--cyan);
}
.border-purple {
  border-color: var(--purple);
}
.no-scrollbar {
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  width: 0;
}

/* Hide increment/decrement arrows in input number fields */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide increment/decrement arrows in input number fields */
input[type=number] {
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  -moz-appearance: textfield; /* Firefox */
  appearance: none; /* Standard */
  margin: 0;
}


.bg-gradient-purple {
  background: var(--gradient-purple);
}
.bg-black {
  background: var(--bg-black);
}

.dark body {
  background-color: var(--bg-black);
  overflow-x: hidden;
}

body {
  background-color: var(--black);
  overflow-x: hidden;
  font-family: "Montserrat";
  position: relative; /* Make the body a positioned container for the pseudo-element */
}
.active {
  color: #6142FF;
}
.rewardBtn:hover .leftBracket {
  transform: translateX(2px) !important;
  transition: transform 0.2s ease;
}

.rewardBtn:hover .rightBracket {
  transform: translateX(-2px) !important;
  transition: transform 0.2s ease;
}

.rewardBtn:focus .leftBracket {
  transform: translateX(2px) !important;
  transition: transform 0.2s ease;
}

.rewardBtn:focus .rightBracket {
  transform: translateX(-2px) !important;
  transition: transform 0.2s ease;
}

.blur-body {
  filter: blur(5px); /* Adjust the blur amount as needed */
}

@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -20px
    ); /* Adjust the value to control the vertical movement */
  }
}

.animate-moveUpDown {
  animation: moveUpDown 3s linear infinite;
}

/*Mobile View*/
@media (min-width: 288px) and (max-width: 767px) {
  .subHeadingText {
    color: #fff;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    /* line-height: 14px; */
  }
  .mainSubHeading {
    color: #fff;
    text-shadow: 4px 4px 0px 0px #000;
    font-family: var(--ppsupplymono-regular);
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
  }
  .mainSubHeading:hover {
    color: #6142ff;
  }
  .boxText {
    color: #ffffff;
    font-family: var(--ppsupplymono-regular);
    font-size: 16px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 1px;
  }
  /* body {
    height: auto; 
  } */
}
/*iPad Mini*/
@media (min-width: 768px) and (max-width: 1023px) {
  .subHeadingText {
    color: #fff;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
  }
  .mainSubHeading {
    color: #fff;
    text-shadow: 4px 4px 0px 0px #000;
    font-family: var(--ppsupplymono-regular);
    font-size: 45px;
    font-style: normal;
    font-weight: 900;
  }
  .mainSubHeading:hover {
    color: #6142ff;
  }
  .boxText {
    color: #ffffff;
    font-family: var(--ppsupplymono-regular);
    font-size: 18px;
    font-style: normal;

    line-height: 18px;
  }
  /* body {
    height: auto; 
  } */
}
/*iPad Pro*/
@media (min-width: 1024px) and (max-width: 1136px) {
  .subHeadingText {
    color: #fff;
    font-family: Montserrat;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
  }
  .mainSubHeading {
    color: #fff;
    text-shadow: 4px 4px 0px 0px #000;
    font-family: var(--ppsupplymono-regular);
    font-size: 45px;
    font-style: normal;
    font-weight: 900;
  }
  .mainSubHeading:hover {
    color: #6142ff;
  }
  .boxText {
    color: #ffffff;
    font-family: var(--ppsupplymono-regular);
    font-size: 18px;
    font-style: normal;
    line-height: 14px;
  }

}
/*Laptop*/
@media (min-width: 1136px) {
  .subHeadingText {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 1000;
    line-height: 14px;
  }
  .mainSubHeading {
    color: #fff;
    text-shadow: 4px 4px 0px 0px #000;
    font-family: var(--ppsupplymono-regular);

    font-size: 45px;
    font-style: normal;
    font-weight: 900;
  }
  .mainSubHeading:hover {
    color: #6142ff;
  }

  .boxText {
    color: #ffffff;
    font-family: var(--ppsupplymono-regular);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
  }


  /* .boxText:hover {
  color: #0000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
} */
}

/* @media screen and (max-wdith: 767px) {
  .pcountdown {
    width: 40px;
  }
} */
@media (min-width: 768px) {
  .pcountdown {
    padding: 30px 20px 27px 20px;
    margin-bottom: 0.5rem;
    width: 80px;
    height: 80px;
    font-size: 24px;
  }
  .single-entity {
    margin-right: 2px;
    margin-left: 2px;
  }
}

/* {Animations of INFINITY} */
.heroLeftSection {
  animation: fadeRight 1s ease-in-out 1;
}
.heroRightSection {
  animation: fadeLeft 1s ease-in-out 1;
}
@keyframes fadeRight {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*------------------------
	slidefadeinup
--------------------------*/

@-webkit-keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.slideFadeInUp {
  -webkit-animation-name: slideFadeInUp;
  animation-name: slideFadeInUp;
  animation: slideFadeInUp 2s;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

.dark table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #6142ff;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000; /* Add this line to create a vertical line between columns */
}

.dark th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #6142ff;
  border-right: 1px solid #6142ff; /* Add this line to create a vertical line between columns */
}

.dark td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #6142ff;
  border-right: 1px solid #6142ff; /* Add this line to create a vertical line between columns */
}
th {
  background-color: transparent;
}
th:last-child,
td:last-child {
  border-right: none; /* Remove the right border on the last column */
}
@media only screen and (max-width: 600px) {
  table {
    font-size: 14px;
    overflow-x: auto;
  }
}
